import "./Result.css";

export const Result = ({ data }) => {
  return (
    <div className="result-container">
      <div className="result-prompt">
        <div className="result-label">Prompt:</div>
        <div className="result-value">{data.prompt}</div>
      </div>
      <div className="result-response">
        <div className="result-label">Response:</div>
        <div className="result-value">{data.response}</div>
      </div>
    </div>
  );
};
