import React, { useState } from "react";
import { InputForm } from "./InputForm";
import { Result } from "./Result";
import "./Global.css";

export function App() {
  const [resultList, setResultList] = useState([]);

  const handleSuccess = (result) => {
    setResultList((oldResultList) => [result, ...oldResultList]);
  };

  return (
    <main className="container">
      <h1>Fun with AI</h1>
      <InputForm onSuccess={handleSuccess} />
      <section aria-live="polite">
        <h2>Responses</h2>
        {resultList.map((resultItem, index) => (
          <Result data={resultItem} key={index} />
        ))}
        {resultList.length === 0 && (
          <span>No responses yet - enter a prompt in the form above.</span>
        )}
      </section>
    </main>
  );
}
