import { useState } from "react";
import { openAiRequest } from "./openAiRequest";
import "./InputForm.css";

export const InputForm = ({ onSuccess }) => {
  const [inputVal, setInputVal] = useState(
    "Write a restaurant review based on the following:\n\nrude waiters, bad tacos, food poisoning"
  );

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);
    const prompt = inputVal;
    const data = await openAiRequest(inputVal);
    onSuccess({
      prompt,
      response: data.choices[0]?.text,
    });
    setInputVal("");
    setLoading(false);
  };

  return (
    <section>
      <form onSubmit={handleSubmit} className="input-form">
        <label>
          <span className="label">Enter prompt</span>
          <textarea
            className="text-area"
            disabled={loading}
            rows="10"
            type="text"
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
          />
        </label>
        <button type="submit" className="submit-button">
          {loading ? <div className="lds-dual-ring" /> : "Submit"}
        </button>
      </form>
    </section>
  );
};
